//Note: injects jobTracker, rather than consuming a jobs object,
//because in my testing one-way binding does not seem to work when injected using $resolve
function QueueController($scope, jobEvents, jobTracker, $routeParams, $log, $location, $timeout, $q) {
  // TODO: Use services to keep track of last viewed job.
  this.initialized = false;

  this.checkingStatus = false;
  this.checkingStatusSuccess = false;
  this.checkingStatusError = false;

  let completionListener;
  let failedListener;
  let progressListener;

  Object.defineProperty(this, 'jobBeingViewed', {
    get: () => this._id ? jobTracker.jobs.all[this._id] : {},
  });

  this.hasJob = () => !!Object.keys(this.jobBeingViewed).length;

  const clearListeners = () => {
    // Clear an existing listener
    if(completionListener) {
      completionListener();
    }

    if(failedListener) {
      failedListener();
    }

    if(progressListener) {
      progressListener();
    }
  };

  const setListeners = () => {
    if(this.jobBeingViewed) {
      if(completionListener) {
        completionListener();
      }

      if(failedListener) {
        failedListener();
      }

      if(progressListener) {
        progressListener();
      }

      // Not needed; the job tracker triggers watch cycle, and updates reference
      // progressListener = $scope.$on(
      // `${jobTracker.jobUpdatedEvent}:${this.jobBeingViewed._id}`, (event, rJob) => {
      //   console.info('received', event);
      //   this.jobBeingViewed = rJob;
      // });

      completionListener = $scope.$on(
      `${jobEvents.eventPrefix}${jobEvents.events.annotation.completed}`, (event, rJob) => {
        if(rJob._id === this.jobBeingViewed._id) {
          $location.path('/results').search({_id: rJob._id});
        }
      });

      failedListener = $scope.$on(
      `${jobEvents.eventPrefix}${jobEvents.events.annotation.failed}`, (event, rJob) => {
        if(rJob._id === this.jobBeingViewed._id) {
          $location.path('/queue/failed').search({_id: rJob._id});
        }
      });
    }
  };

  // Note that for now we do not allow 'completed' type jobs in the queue
  // However, for now this is fine; every job that is changed to completed
  // during its time in the queue will have us redirected to the /results section
  // TODO: We need to clarify the role of the queue component
  // TODO: simplify this, so that queue routes all jobs, including results
  // by the passed type
  const typeChanged = (job) => {
    return this.jobType === 'all' ||
    (job.isCompleted() && this.jobType !== 'completed' ||
     job.isFailed() && this.jobType !== 'failed' ||
     job.isIncomplete() && this.jobType !== 'incomplete');
  };

  this.clearJobBeingViewed = () => {
    clearListeners();
    this._id = null;
    $location.search('_id', null);
  };

  this.clear = this.clearJobBeingViewed;

  this.checkStatus = (job) => {
    this.checkingStatus = true;

    if(!job) {
      job = this.jobBeingViewed;
    }

    return job.$checkStatus().then( (jResponse) => {
      this.checkingStatusSuccess = true;

      const [err, updatedJob] = jobTracker.trackJobUpdate(jResponse);
      if(err) {
        return $q.reject(err);
      }

      const _id = this._id = updatedJob._id;

      if(updatedJob.isDeleted()) {
        this.clearJobBeingViewed();
        return;
      }

      if(!typeChanged(updatedJob)) {
        $location.search({_id});
        setListeners();
        return;
      }

      if(updatedJob.isCompleted()) {
        clearListeners();
        $location.path('/results').search({_id});
        return;
      }

      if(updatedJob.isFailed()) {
        clearListeners();
        $location.path('/queue/failed').search({_id});
        return;
      }

      if(updatedJob.isIncomplete()) {
        clearListeners();
        $location.path('/queue/incomplete').search({_id});
        return;
      }

      return updatedJob;
    }).catch((err) => {
      this.checkingStatusError = err;
      return err;
    }).finally(() => {
      $timeout(() => {
        this.checkingStatus = false;
        this.checkingStatusSuccess = false;
        // Keep error visible
      }, 250);
    });
  };

  this.updateJob = (id) => {
    if(!id) {
      $q.reject("Need id in updateJob");
      return;
    }

    // We cannot pass a one-way bound job to the component from route resolver
    // and have reference changes propagate... annoying.
    // So we do this instead
    this.fetchingData = true;

    // The only point here is to give jobTracker the latest info
    // jobBeingViewed will automatically contain the latest via reference-tracking
    // or if we choose to move away from that, can be updated using $scope.$on 
    // to listen on jobTracker's jobUpdateEvent broadcast
    // We force updates to get latest info
    return jobTracker.initializeAsync().then(() => {
      return jobTracker.getOneAsync(id, true).finally(() => {
        this.fetchingData = false;
      });
    });
  };

  this.updateAndCheckStatus = (id) => {
    this.updateJob(id).then((updatedJob) => {
      this.checkStatus(updatedJob);
    });
  };

  this.$onInit = () => {
    jobTracker.initializeAsync();
  };

  this.$onChanges = (changesObject) => {
    if(!changesObject._id.currentValue) {
      this.clearJobBeingViewed();
    }

    if(changesObject._id && changesObject._id.currentValue && changesObject._id.currentValue !== changesObject._id.previousValue) {
      this.updateAndCheckStatus(changesObject._id.currentValue);
    }
  };
}

angular.module('sq.jobs.queue.component',
['sq.jobs.tracker.service',
'sq.jobs.chooser.component', 'sq.jobs.events.service',
'sq.jobs.infoCard.component', 'sq.jobs.queue.status.component'])
.component('sqJobQueue', {
  bindings: {
    jobType: '<',
    _id: '<id',
  }, // isolate scope
  templateUrl: 'jobs/queue/jobs.queue.tpl.html',
  controller: QueueController,
});